import React, { useContext } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "styled-components";
import { LangContext } from "../../context/langProvider";
import useLanguages from "../../hooks/useLanguages";
import useSiteUrl from "../../hooks/useSiteUrl";
import { saveLocale } from "./utils";

// Scoped styles

const LangNavList = styled.ul`
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  column-gap: var(--gapRegular);

  & li span {
    cursor: not-allowed;

    &:hover {
      color: var(--disabledColor);
    }
  }

  .activeClassLangNav {
    color: var(--primaryColor);
    &:hover {
      color: var(--primaryColor);
    }
  }

  @media screen and (max-width: 767px) {
    column-gap: 0;
  }
`;

const LangNav = styled.nav`
  text-transform: uppercase;
  &&& {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const LanguageSwitcherLink = styled(Link)`
  font-weight: 600;
  color: var(--disabledColor);
  transition: color 0.2s linear;

  &:hover {
    color: var(--primaryColor);
  }

  @media screen and (max-width: 767px) {
    padding: var(--gapSmall);
  }
`;

// Main Component

const LanguageSwitcher = () => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsSite {
        nodes {
          locale
        }
      }
      allSitePage {
        nodes {
          pageContext
        }
      }
    }
  `);

  const { defaultLanguage, defaultBlogPath } = useLanguages();

  const { siteUrl } = useSiteUrl();

  const {
    currentLanguage,
    pageType,
    slug: pageSlug,
    archivePageNumber,
  } = useContext(LangContext);

  // Helpers

  const isHome = pageType === "isHome";

  const isPage = pageType === "isPage";

  const isArchiveRoot = pageType === "isArchiveRoot";

  const isPaginatedArchive = pageType === "isPaginatedArchive";

  const isPost = pageType === "isPost";

  return (
    <LangNav>
      {isHome ? (
        <LangNavList>
          {data.allDatoCmsSite.nodes.map(({ locale }) => (
            <li key={locale}>
              <LanguageSwitcherLink
                className={locale === currentLanguage && "activeClassLangNav"}
                as={locale === currentLanguage && "span"}
                to={locale === defaultLanguage ? "/" : `/${locale}`}
                onClick={() => saveLocale(siteUrl, locale)}
              >
                {locale}
              </LanguageSwitcherLink>
            </li>
          ))}
        </LangNavList>
      ) : isArchiveRoot ? (
        <LangNavList>
          {data.allDatoCmsSite.nodes.map(({ locale }) => (
            <li key={locale}>
              <LanguageSwitcherLink
                className={locale === currentLanguage && "activeClassLangNav"}
                as={locale === currentLanguage && "span"}
                to={
                  locale === defaultLanguage
                    ? `/${defaultBlogPath}`
                    : `/${locale}/${defaultBlogPath}`
                }
                onClick={() => saveLocale(siteUrl, locale)}
              >
                {locale}
              </LanguageSwitcherLink>
            </li>
          ))}
        </LangNavList>
      ) : isPaginatedArchive ? (
        <LangNavList>
          {data.allDatoCmsSite.nodes.map(({ locale }) => (
            <li key={locale}>
              <LanguageSwitcherLink
                className={locale === currentLanguage && "activeClassLangNav"}
                as={locale === currentLanguage && "span"}
                to={
                  locale === defaultLanguage
                    ? `/${defaultBlogPath}/${archivePageNumber}`
                    : `/${locale}/${defaultBlogPath}/${archivePageNumber}`
                }
                onClick={() => saveLocale(siteUrl, locale)}
              >
                {locale}
              </LanguageSwitcherLink>
            </li>
          ))}
        </LangNavList>
      ) : (
        (isPost || isPage) && (
          <LangNavList>
            {data.allDatoCmsSite.nodes.map(({ locale }) =>
              // Iterate through all available languages..
              locale === currentLanguage ? (
                // Disable the anchor tag if I'm rendering the link for the same locale of the page
                <li key={locale}>
                  <LanguageSwitcherLink
                    as="span"
                    className="activeClassLangNav"
                  >
                    {locale}
                  </LanguageSwitcherLink>
                </li>
              ) : (
                // Else, iterate through all the pages generated by gatsby-node.js and check...
                data.allSitePage.nodes.map(
                  ({
                    pageContext: {
                      locale: contextLocale,
                      slug: contextSlug,
                      reference: contextReference,
                    },
                  }) =>
                    contextSlug === pageSlug &&
                    // Is there a page with the same slug of the page I'm rendering the component into
                    contextLocale === currentLanguage &&
                    // Which has the same locale of the page that I'm rendering the link into?
                    //
                    // The above condition will occur only once ensuring no duplicated languages links
                    // are rendered inside the switcher when a page has the same slug for different languages
                    //
                    // Then, iterate again through all the pages...
                    data.allSitePage.nodes.map(
                      ({
                        pageContext: {
                          locale: matchLocale,
                          slug: matchSlug,
                          reference: matchReference,
                        },
                      }) =>
                        matchLocale === locale &&
                        // Is there a page of the same locale I am rendering the link
                        matchReference === contextReference && (
                          // which has the same reference of the page found before?
                          <li key={locale}>
                            <LanguageSwitcherLink
                              to={
                                isPost
                                  ? locale === defaultLanguage
                                    ? `/${defaultBlogPath}/${matchSlug}`
                                    : `/${locale}/${defaultBlogPath}/${matchSlug}`
                                  : isPage
                                  ? locale === defaultLanguage
                                    ? `/${matchSlug}`
                                    : `/${locale}/${matchSlug}`
                                  : "/"
                              }
                              onClick={() => saveLocale(siteUrl, locale)}
                            >
                              {locale}
                            </LanguageSwitcherLink>
                          </li>
                        )
                    )
                )
              )
            )}
          </LangNavList>
        )
      )}
    </LangNav>
  );
};

export default LanguageSwitcher;
